import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { $getSelectionStyleValueForProperty } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import RichTextSeparator from "../../../Separators/RichTextSeparator/RichTextSeparator";
import { ToolbarContainer } from "./ToolbarPlugin.styled";
import FormatButton from "./FormatButton/FormatButton";
import BoldIcon from "../../../Icon/Icons/BoldIcon";
import ItalicIcon from "../../../Icon/Icons/ItalicIcon";
import UnderlineIcon from "../../../Icon/Icons/UnderlineIcon";
import LeftAlignIcon from "../../../Icon/Icons/LeftAlignIcon";
import CenterAlignIcon from "../../../Icon/Icons/CenterAlignIcon";
import RightAlignIcon from "../../../Icon/Icons/RightAlignIcon";
import JustifyIcon from "../../../Icon/Icons/JustifyIcon";
import UnorderedListIcon from "../../../Icon/Icons/UnorderedListIcon";
import OrderedListIcon from "../../../Icon/Icons/OrderedListIcon";
import {
  BOLD,
  CENTER_ALIGN,
  FONT_SIZE,
  ITALIC,
  JUSTIFY,
  LEFT_ALIGN,
  ORDERED_LIST,
  PARAGRAPH,
  RIGHT_ALIGN,
  UNDERLINE,
  UNORDERED_LIST,
} from "../../../../constants/richTextConstants";
import FontDropdown from "./FontDropdown/FontDropdown";

const lowPriority = 1;

const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState(PARAGRAPH);
  const [isBold, setIsBold] = useState(false);
  const [fontSize, setFontSize] = useState("10pt");
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const formatBulletList = () => {
    if (blockType !== UNORDERED_LIST) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== ORDERED_LIST) {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      setIsBold(selection.hasFormat(BOLD));
      setIsItalic(selection.hasFormat(ITALIC));
      setIsUnderline(selection.hasFormat(UNDERLINE));
      setFontSize(
        $getSelectionStyleValueForProperty(selection, FONT_SIZE, "10pt")
      );
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        lowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <ToolbarContainer ref={toolbarRef}>
      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, BOLD);
        }}
        isActive={isBold}
        icon={<BoldIcon />}
      />

      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, ITALIC);
        }}
        isActive={isItalic}
        icon={<ItalicIcon />}
      />
      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, UNDERLINE);
        }}
        isActive={isUnderline}
        icon={<UnderlineIcon />}
      />
      <FontDropdown
        styleProperty={FONT_SIZE}
        value={fontSize}
        editor={editor}
      />
      <RichTextSeparator />
      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, LEFT_ALIGN);
        }}
        icon={<LeftAlignIcon />}
      />
      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, CENTER_ALIGN);
        }}
        icon={<CenterAlignIcon />}
      />
      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, RIGHT_ALIGN);
        }}
        icon={<RightAlignIcon />}
      />
      <FormatButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, JUSTIFY);
        }}
        icon={<JustifyIcon />}
      />
      <RichTextSeparator />
      <FormatButton
        icon={<UnorderedListIcon />}
        isActive={blockType === "ul"}
        onClick={formatBulletList}
      />
      <FormatButton
        icon={<OrderedListIcon />}
        isActive={blockType === "ol"}
        onClick={formatNumberedList}
      />
    </ToolbarContainer>
  );
};

export default ToolbarPlugin;
