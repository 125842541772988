
import TypeRenderer from "../../components/Findings/FindingsTable/Renderers/TypeRenderer/TypeRenderer";
export default {
  caption: {
    id: 0,
    i18key: "common.name",
    style: {
      minWidth: "443.5px",
    },
    backendProperty: "caption",
    redirect: true,
    setMaxHeight: true,
    disableSort: true,
  },
  category: {
    id: 1,
    i18key: "commonTemplate.filter.categoryTitle",
    renderer: TypeRenderer,
    disableSort: true,
    style: {
      paddingRight: "0",
      minWidth: "168px",//calc(100% /6)", 
    },
    backendProperty: "category",
   
  },
  header: {
    id: 2,
    i18key: "commonTemplates.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
};