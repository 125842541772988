import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import {
  CancelButton,
  ImportSubjectModalContainer,
  ImportSubjectSelectContainer,
  SubmitButton
} from "./ImportSubjectModal.styled";
import SubjectPreview from "./SubjectPreview/SubjectPreview";
import TemplatesTable from "./TemplatesTable/TemplatesTable";
import {fetchCreateCommonTemplate, fetchSingleCommonTemplate} from "../../../store/actions/commonTemplates/commonTemplatesActions";
import { selectSingleCommonTemplate } from "../../../store/selectors/commonTemplatesSelector";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";



const ImportSubjectModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const singleCommonTemplate = useSelector(selectSingleCommonTemplate);
  const [value, setValue] = useState(null);
 
 // const handleItemClick = (newValue) => {
   // setValue(newValue);
  //};
 

  const handleRowClick = (rowData) => {
    dispatch(fetchSingleCommonTemplate({commonTemplateId: rowData.id}));
    setValue(rowData)
  };
 

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {

    const updatedSingleCommonTemplate = {
      ...singleCommonTemplate,
      findingTypeId:props?.value.id
    }
    dispatch(fetchCreateCommonTemplate({data: updatedSingleCommonTemplate, handleApiResponseSuccess: (response) => handleApiResponseSuccess(response)}));
    
  };

  const handleApiResponseSuccess = (response) => {
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t("toast.success.createdTitle", 
          {
                typeOfData: t("scanningObjects.typeOfData")
         }),
        description: t("toast.success.createdDescription", {
          name: response?.name ,
          typeOfData: t("scanningObjects.typeOfData").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.forceRefetch();
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <ImportSubjectModalContainer rank={props?.rank}>
        <ModalTitle>{t(props?.title)}</ModalTitle>
        <CloseButton onClick={handleCloseModal} />
        {/* <ImportSubjectSelectContainer> */}
        {/* <ImportSubjectSelect value={value} onChange={handleChange} /> */}
        {/* </ImportSubjectSelectContainer> */}
        <ImportSubjectSelectContainer>
          <TemplatesTable onRowClick={handleRowClick}   />
          <SubjectPreview valueId={value}/>
        </ImportSubjectSelectContainer>
        <ButtonsContainer>
          <CancelButton
            onClick={handleCloseModal}
            $variant={props?.cancelButtonVariant}
          >
            {t("common.cancel")}
          </CancelButton>
          <SubmitButton onClick={handleSubmit}>{t("common.add")}</SubmitButton>
        </ButtonsContainer>
      </ImportSubjectModalContainer>
    </>
  );
};

ImportSubjectModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelButtonVariant: PropTypes.number,
  rank: PropTypes.number,
  history: PropTypes.any,
  handleSubmit: PropTypes.func,
  value: PropTypes.any,
  tableRef: PropTypes.any,
  forceRefetch: PropTypes.func,
};

ImportSubjectModal.defaultProps = {
  cancelButtonVariant: 1,
  data: [],
  handleSubmit: () => {},
};

export default ImportSubjectModal;
