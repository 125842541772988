import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import deviceConstants from "../../../../../../constants/deviceConstants";
import ViewRadio from "../ViewRadio/ViewRadio";
import {
  NoteTextareaField,
  QuestionSeparator,
} from "../FindingScanningSectionContent.styled";
import FindingQuestionCard from "../../../../../Cards/FindingQuestionCard/FindingQuestionCard";
import FindingScanningWizzard from "../FindingScanningWizzard/FindingScanningWizzard";
import {
  FindingQuestionContainer,
  FindingScanningQuestionsHeader,
  FindingScanningQuestionsHeaderTitle,
} from "./FindingScanningQuestions.styled";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  setConclusion,
  setFindingsChanged,
  setFindingSectionNote,
  setFindingSubjectNote,
} from "../../../../../../store/actions/findings/findingsActions";
import FindingSeparator from "../../../../../Separators/FindingSeparator/FindingSeparator";
import {
  selectIsFindingSettingsChanged,
  selectScanningSubjectConclusion,
  selectSingleFinding,
  selectSingleFindingToEdit,
} from "../../../../../../store/selectors/findingsSelectors";
import FindingCheckboxes from "./FindingCheckboxes";
import {
  emptyHtml,
  makeParagraphInline,
  trimNewLineAtEnd,
} from "../../../../../../util/helpers/htmlHelpers";
import {
  CLIENT_CONSTANT,
  LOCATION_CONSTANT,
  OBJECT_CONSTANT,
} from "../../../../../../constants/subjectNoteSettingsConstants";

const FindingScanningQuestions = (props) => {
  const { t } = useTranslation();
  const [selectedDevice, setSelectedDevice] = useState(props?.device);
  const [sectionNoteValue, setSectionNoteValue] = useState("");
  const [subjectNoteValue, setSubjectNoteValue] = useState("");
  const [makeChanges, setMakeChanges] = useState(false);
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const singleFinding = useSelector(selectSingleFinding);
  const singleFindingToEdit = useSelector(selectSingleFindingToEdit);
  const conclusion = useSelector(
    selectScanningSubjectConclusion(props?.chosenScanningSubject?.id)
  );
  const scanningSubjectId = useRef(props?.chosenScanningSubject?.id)

  useEffect(() => {
    setSectionNoteValue(props?.section?.note?.value || "");
  }, [props?.section?.note?.value]);
  useEffect(() => {
    setSubjectNoteValue(
      props?.subjectNote?.value ? props?.subjectNote?.value : ""
    );
  }, [props?.subjectNote]);

  useEffect(() => {
    if (isMobile !== undefined && isMobile !== null) {
      if (isMobile) {
        if (selectedDevice !== deviceConstants.mobile)
          setSelectedDevice(deviceConstants.mobile);
      } else {
        if (selectedDevice !== deviceConstants.desktop)
          setSelectedDevice(deviceConstants.desktop);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (scanningSubjectId !== props?.chosenScanningSubject?.id) scanningSubjectId.current = props?.chosenScanningSubject?.id
  }, [props?.chosenScanningSubject])

  const handleChangeSectionNote = (sectionNoteValue) => {
    dispatch(
      setFindingSectionNote({
        sectionId: props?.section?.id,
        subjectId: props?.chosenScanningSubject?.id,
        note: {
          value: sectionNoteValue,
          edited:
            !props?.sectionOld ||
            (() =>
              props?.sectionOld?.note?.value == null ||
              props?.sectionOld?.note?.value?.length === 0
                ? ""
                : props?.sectionOld?.note?.value)() !== sectionNoteValue,
        },
      })
    );
  };
  const handleChangeSubjectNote = (subjectNoteValue) => {
    batch(() => {
      dispatch(
        setConclusion({
          id: scanningSubjectId?.current,
          checkedConclusion: null,
        })
      );
      dispatch(
        setFindingSubjectNote({
          subjectId: scanningSubjectId?.current,
          note: {
            value: subjectNoteValue,
            edited:
              !props?.chosenScanningSubjectOld ||
              (() =>
                props?.chosenScanningSubjectOld?.conclusion?.value == null ||
                props?.chosenScanningSubjectOld?.conclusion?.value?.length === 0
                  ? ""
                  : props?.chosenScanningSubjectOld?.conclusion?.value)() !==
                subjectNoteValue,
          },
        })
      );
    });
  };

  const changeSelectedDevice = (newDevice) => {
    if (selectedDevice !== newDevice) setSelectedDevice(newDevice);
  };

  const makeFindingAbleToSave = () => {
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
  };

  useEffect(() => {
    if (makeChanges && conclusion?.checkedConclusion) {
      let selectedCounclusion =
        conclusion?.checkedConclusion === 1
          ? props?.chosenScanningSubject?.positiveConclusionTemplate
          : props?.chosenScanningSubject?.negativeConclusionTemplate;

      let matches = selectedCounclusion?.match(/\[(.*?)\]/g);
      let answers = [];
      if (matches) {
        props?.chosenScanningSubject?.sections?.forEach?.((section) => {
          section?.items?.forEach?.((item) => {
            if (matches?.includes?.(`[${item.question}]`)) {
              if (
                item?.answer?.value?.trim?.()?.length === 0 ||
                item?.answer?.value === emptyHtml
              ) {
                answers?.push?.({
                  question: item?.question,
                  answer: "",
                });
              } else {
                let newMatchedAnswer = trimNewLineAtEnd(item?.answer?.value);
                if (
                  newMatchedAnswer === emptyHtml ||
                  newMatchedAnswer?.slice(0, -1) === emptyHtml
                )
                  newMatchedAnswer = "";
                answers.push({
                  question: item?.question,
                  answer: makeParagraphInline(newMatchedAnswer),
                });
              }
            }
          });
        });
        selectedCounclusion = answers?.reduce(
          (acc, val) =>
            acc?.replace?.(`[${val?.question}]`, val?.answer)?.trim?.(),
          selectedCounclusion
        );
      }
      CLIENT_CONSTANT.forEach((singleConstant) => {
        if (selectedCounclusion?.includes?.(singleConstant))
          selectedCounclusion = selectedCounclusion?.replace(
            singleConstant,
            singleFindingToEdit?.client?.name ||
              singleFinding?.client?.name ||
              ""
          );
      });
      OBJECT_CONSTANT.forEach((singleConstant) => {
        if (selectedCounclusion?.includes?.(singleConstant))
          selectedCounclusion = selectedCounclusion?.replace(
            singleConstant,
            singleFindingToEdit?.facility?.value ||
              singleFinding?.facility?.value ||
              ""
          );
      });
      LOCATION_CONSTANT.forEach((singleConstant) => {
        if (selectedCounclusion?.includes?.(singleConstant))
          selectedCounclusion = selectedCounclusion?.replace(
            singleConstant,
            singleFindingToEdit?.location?.value ||
              singleFinding?.location?.value ||
              ""
          );
      });

      dispatch(
        setFindingSubjectNote({
          subjectId: props?.chosenScanningSubject?.id,
          note: {
            value: selectedCounclusion,
            edited: true,
          },
        })
      );
      setSubjectNoteValue(selectedCounclusion);
      dispatch(setFindingsChanged(true));
      setMakeChanges(false);
    }
  }, [conclusion?.checkedConclusion, makeChanges]);

  const handleChangeCheckbox = (value) => {
    dispatch(
      setConclusion({
        id: props?.chosenScanningSubject?.id,
        checkedConclusion: value,
      })
    );
    setMakeChanges(true);
  };

  const subjectNoteField = useMemo(() => {
    return (
      <NoteTextareaField
        placeholder={!props?.disabled && t("findings.subjectNotePlaceholder")}
        title={t("findings.subjectNoteTitle")}
        value={subjectNoteValue}
        onBlur={handleChangeSubjectNote}
        disabled={props?.disabled}
        onChange={makeFindingAbleToSave}
        isRichText
      />
    );
  }, [subjectNoteValue, props?.subjectNote?.value, props?.disabled]);

  const changeConclusion = () => {
    setMakeChanges(true);
  };

  //console.log(props);
  return (
    <>
      {!isMobile && (
        <FindingScanningQuestionsHeader>
          <FindingScanningQuestionsHeaderTitle>
            {t("findings.scanning.title")}
          </FindingScanningQuestionsHeaderTitle>
          <ViewRadio
            selected={selectedDevice}
            changeSelectedDevice={changeSelectedDevice}
          />
        </FindingScanningQuestionsHeader>
      )}
      {selectedDevice === deviceConstants.desktop ? (
        props?.questions?.map((singleQuestion, index) => (
          <FindingQuestionContainer key={index}>
            <FindingQuestionCard
              key={JSON.stringify(singleQuestion)}
              question={singleQuestion}
              isSubjectAdded={props?.chosenScanningSubject?.added}
              answerQuestion={props?.answerQuestion}
              disabled={props?.disabled}
              changeConclusion={changeConclusion}
            />
            {index !== props?.questions?.length - 1 && <QuestionSeparator />}
          </FindingQuestionContainer>
        ))
      ) : (
        <FindingScanningWizzard
          items={props?.questions}
          selectedTab={props?.selectedTab}
          answerQuestion={props?.answerQuestion}
          disabled={props?.disabled}
          section={props?.section}
          changeConclusion={changeConclusion}
        />
      )}
      <FindingSeparator />
      {props?.section && (
        <NoteTextareaField
          placeholder={!props?.disabled && t("findings.sectionNotePlaceholder")}
          title={t("findings.sectionNoteTitle")}
          value={sectionNoteValue}
          key={props?.section?.id}
          onBlur={handleChangeSectionNote}
          disabled={props?.disabled}
          style={{ marginBottom: "26px" }}
          onChange={makeFindingAbleToSave}
          isRichText
        />
      )}
      <FindingSeparator full />
      <FindingCheckboxes
        disabled={props?.disabled}
        setCheckedConclusion={handleChangeCheckbox}
        checkedConclusion={conclusion?.checkedConclusion}
      />
      {subjectNoteField}
      {/* <FindingsActionButtons handleClickSubmitButton={handleClickSubmit} /> */}
    </>
  );
};

FindingScanningQuestions.propTypes = {
  questions: PropTypes.array,
  answerQuestion: PropTypes.func,
  handleChangeTab: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedTab: PropTypes.object,
  section: PropTypes.object,
  sectionOld: PropTypes.object,
  goBackToSections: PropTypes.func,
  disabled: PropTypes.bool,
  subjectNote: PropTypes.any,
  breadcrumbs: PropTypes.array,
  selectedScanningSubjectId: PropTypes.number,
  chosenScanningSubject: PropTypes.any,
  chosenScanningSubjectOld: PropTypes.any,
  device: PropTypes.oneOf([deviceConstants.desktop, deviceConstants.mobile]),
};

export default FindingScanningQuestions;
