import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";
import XIcon from "../Icon/Icons/XIcon";

export const ModalContainer = styled(Box)`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 151;
  & > div {
    max-height: 97vh;
    @media (max-width: 600px) {
      max-height: 100vh;
    }
  }
`;
export const ModalInnerContainer = styled(Box)`
  width: fit-content;
  height: fit-content;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 16px;
  }
  /* position: absolute;
  top: 20%;
  left: 30%; */
`;
export const ModalTitle = styled(Box)`
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-feature-settings: "salt" on;
  text-align: left;
  color: ${selectedTheme.colors.titleColor};
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const CloseButton = styled(XIcon)`
  position: absolute;
  top: 33px;
  right: 26px;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center; //
`;
export const CancelButton = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  height: 48px;
  background: white;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  border-radius: 6px;
`;
export const SubmitButton = styled(Box)`
  /* padding: 10px 24px; */
  cursor: ${(props) => (props?.disabled ? "default" : "pointer")};
  flex: 1;
  height: 48px;
  border-radius: 6px;
  background: ${selectedTheme.colors.saveButtonBackground};
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props?.disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledButtonBackground};
    `}
`;
