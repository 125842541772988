import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";

export const LexicalEditorContainer = styled(Box)`
  ${(props) =>
    props?.disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledFieldBackground};
    `}
`;
export const LexicalContainer = styled(Box)`
  margin-top: 20px;
  border: 1px solid ${selectedTheme.colors.inputBorderColor};
  border-radius: 8px;
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};

  .editor-input {
    font-size: 10pt;
  }
`;
export const LexicalInnerContainer = styled(Box)``;
